import React from "react"
import { graphql, Link } from "gatsby"
import { Container } from "react-bootstrap"

import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import UredaleVideoMp4 from "../../src/video/uredale.mp4"
import UredaleVideoWebm from "../../src/video/uredale.webm"

import Content from "../components/content"
import MasonryGrid from "../components/masonry-grid"

const VideoWrapper = styled.div`
  position: relative;
  height: 70vh;
  overflow: hidden;
`
const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const VideoText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);

  h1{
    @media(max-width:550px){
      font-size:2.25rem;
    }
  }

`

const IndexPage = ({ data: { home, images } }) => (
  <Layout>
    <SEO title="Home" />
    <VideoWrapper>
      <Video width="100%" muted autoPlay loop playsInline>
        <source src={UredaleVideoMp4} type="video/mp4" />
        <source src={UredaleVideoWebm} type="video/webm" />
      </Video>
      <VideoText className="p-4 p-md-0">
        <div>
          <h1 className="display-4 mb-4">
            Handmade Art Glass
            <br /> by Tim Simon
          </h1>
          <Link to="/contact-us" className="btn btn-primary btn-lg">
            Contact us
          </Link>
        </div>
      </VideoText>
    </VideoWrapper>
    <Container className="py-5">
      <Content data={home.references} />
    </Container>
    <div className="mb-3">
      <MasonryGrid col={12} files={home.images} />
    </div>
  </Layout>
)

export const query = graphql`
  {
    home: contentfulPage(slug: { eq: "home" }) {
      name
      slug
      references {
        ... on ContentfulTextLayout {
          title
          richText {
            raw
          }
          image {
            title
            file {
              url
            }
          }
        }
      }
      images {
        ... on ContentfulProductImage {
          name
          image {
            file {
              url
            }
          }
          price
        }
      }
    }
  }
`

export default IndexPage
