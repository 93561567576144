import React from "react"
import styled from "styled-components"

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import { Col } from "react-bootstrap"

const Gallery = styled.div`
  .img-wrapper {
    position: relative;
    height: 100%;
    &:hover {
      .text {
        opacity: 1;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: white;
      background: rgba(0, 0, 0, 0.65);
      opacity: 0;
      transition: 0.3s all ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;
    }
  }
`
const MasonryGrid = ({ files, col }) => (
  <Gallery>
    <Col className="mx-auto" xs={col || 10}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 500: 1, 750: 2, 900: 3 }}>
        <Masonry columnsCount={3} gutter="10px">
          {files.map((file, i) => (
            <div className="img-wrapper" key={i}>
              <img
                key={i}
                src={file.image.file.url}
                alt={file.name}
                style={{ width: "100%", display: "block" }}
              />
              <div className="text">
                {file.name && <p>{file.name}</p>}
                {file.price && <p>{file.price}</p>}
              </div>
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </Col>
  </Gallery>
)

export default MasonryGrid
