import React from "react"

import { Row, Col } from "react-bootstrap"

import Tools from "../images/tools.jpg"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}

const TextContent = ({ data }) => {
  return data.map(text => {
    const content = text.richText.raw
    const contentObj = JSON.parse(content)
    return (
      <Row className="align-items-center">
        {console.log(contentObj)}
        <Col lg={6}>
          <div className="p-2 p-md-4">
            <img src={Tools} alt="Tools" className="img-fluid rounded" />
          </div>
        </Col>
        <Col lg={6}>
          <h2 className="mt-4 mt-md-0">{text.title}</h2>
          {documentToReactComponents(contentObj, options)}
        </Col>
      </Row>
    )
  })
}
export default TextContent
